import React from "react";
import "./UText.scss";
import "./UTextMob.scss";

export default function UText({ html, text, type2, children, className, ...restProps }) {
  let classNameInner = ["text"];
  if (className) classNameInner.push(className);
  if (type2) classNameInner.push("_type-2");
  classNameInner = classNameInner.join(" ");

  if (html) {
    return <div className={classNameInner} dangerouslySetInnerHTML={{ __html: html }} {...restProps} />;
  }

  if (text) {
    return (
      <div className={classNameInner} {...restProps}>
        <p>{text}</p>
      </div>
    );
  }

  return (
    <div className={classNameInner} {...restProps}>
      {children}
    </div>
  );
}
