import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import IconArrowDown from "../UIcon/icons/IconArrowDown";
import CaseArticle from "../CaseArticle";
import UPreloader from "../UPreloader/UPreloader";

import "./cases-articles-section.scss";
import "./cases-articles-section-mob.scss";
import { useDispatch, useSelector } from "react-redux";
import { getCasesList, setCaseSSR, obtainCaseData } from "../../actions";

export const apiMapper = {
  services: {
    baseApi: "cases_categories",
    categoriesId: {
      mobile: "19",
      web: "18",
      design: "23",
      ba: "21",
      legacy: "18", //TODO Find needed  index
      qa: "20",
      consulting: "19", //TODO Find needed  index
      mvp: "22",
    },
  },
  expertise: {
    baseApi: "expertise",
    categoriesId: {
      ecommerce: "28",
      foodtech: "32",
      healthcare: "33",
      education: "29,33", //second id, if category cases have < 3 items
      entertainment: "31",
      realestate: "35,33",
      hospitality: "34,33",
      enterprise: "30",
    },
  },
};

export default function CasesArticlesSection({ className }) {
  const [pending, setPending] = useState(false);
  const location = useLocation();
  const [page, subPage] = location.pathname.replace("/", "").split("/");
  const isCaseSSR = useSelector(state => state.caseStudies.isCaseSSR);
  const articles = useSelector(state => state.caseStudies.caseList);
  const dispatch = useDispatch();
  let getParams = {
    status: "publish",
    orderby: "date",
    order: "desc",
    per_page: "3",
    _embed: 1,
  };

  if (page === "services" || page === "expertise") {
    getParams = {
      ...getParams,
      [apiMapper[page].baseApi]: apiMapper[page].categoriesId[subPage],
    };
  }
  const loadCaseStudies = async () => {
    setPending(true);
    await dispatch(getCasesList(getParams));
    setPending(false);
  };

  useEffect(() => {
    if (isCaseSSR) {
      dispatch(setCaseSSR(false));
    } else {
      loadCaseStudies();
    }

    return () => {
      dispatch(obtainCaseData([], 0));
    };
  }, [subPage]);

  return (
    <section className={`cases-articles-section ${className || ""}`}>
      <div className="cases-articles-section__head">
        <h2 className="cases-articles-section__title">Case Studies</h2>
        <Link to="/case-studies" className="cases-articles-section__more  _desc">
          <span>More cases</span>
          <IconArrowDown />
        </Link>
      </div>
      {pending ? (
        <UPreloader inline />
      ) : (
        <div className="cases-articles-section__list">
          {Array.isArray(articles) &&
            articles.map((article, index) => (
              <CaseArticle lazyImg={true} article={article} key={index} className="cases-articles-section__item" />
            ))}
        </div>
      )}

      <Link to="/case-studies" className="cases-articles-section__more  _mob">
        <span>More cases</span>
        <IconArrowDown />
      </Link>
    </section>
  );
}
