import React from "react";
import { Link, useHistory } from "react-router-dom";
import TagsList from "../TagsList/TagsList";
import UButton from "../UButton/UButton";
import UText from "../UText/UText";

import "./case-article.scss";
import "./case-article-mob.scss";
import Img from "../Img/Img";

export default function CaseArticle({ article, className, lazyImg }) {
  const history = useHistory();
  const tags =
    article &&
    article.cases_categories &&
    article.cases_categories.length &&
    article._embedded &&
    article._embedded["acf:term"] &&
    Array.isArray(article._embedded["acf:term"])
      ? article._embedded["acf:term"].map(el => {
          return {
            name: el.name,
            link: `/case-studies?category=${el?.slug}`,
          };
        })
      : null;

  const link = `/case-studies/${article?.slug ?? ""}`;
  const handleClick = () => history.push(link);
  return (
    <div className={`case-article ${className}`} onClick={handleClick}>
      <div className="case-article__img-wrap">
        <Img
          className="case-article__img"
          src={article?.acf?.thumbnail ?? ""}
          alt={article?.title?.rendered ?? ""}
          lazy={lazyImg}
        />

        <div className="case-article__img-overlay">
          <UButton secondary={true} className="case-article__img-overlay-btn">
            View Details
          </UButton>
        </div>
      </div>
      <div className="case-article__content">
        <h3 className="case-article__title">
          <Link to={link}>{article?.title?.rendered}</Link>
        </h3>

        {article?.acf?.shortDescription ? (
          <UText html={article?.acf?.shortDescription ?? ""} type2={true} className="case-article__text" />
        ) : null}

        {tags ? <TagsList items={tags} className="case-article__tags" /> : null}
      </div>
    </div>
  );
}
