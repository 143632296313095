import React from "react";
import UIcon from "../UIcon";

/* eslint-disable no-unused-vars */
export default function IconArrowDown({ children, className = "", ...restProps }) {
  return (
    <UIcon
      width="12"
      height="8"
      viewBox="0 0 12 8"
      path="M9.87461 0.999521L5.99461 4.87951L2.11461 0.999521C1.92778 0.812261 1.67413 0.707031 1.40961 0.707031C1.14509 0.707031 0.891439 0.812261 0.704609 0.999521C0.314609 1.38952 0.314609 2.01951 0.704609 2.40951L5.29461 6.99951C5.68461 7.38951 6.31461 7.38951 6.70461 6.99951L11.2946 2.40951C11.6846 2.01951 11.6846 1.38952 11.2946 0.999521C10.9046 0.619521 10.2646 0.609521 9.87461 0.999521Z"
      {...restProps}
    />
  );
}
