import React from "react";

import RequestForm from "../../components/RequestForm";
import UContainer from "../../components/UContainer/UContainer";
import "./RequestSection.scss";

/* eslint-disable react/display-name */
export default function ({ sourceName }) {
  return (
    <section className="fifth-section" id="request-form">
      <UContainer>
        <div className="requestWrapper">
          <RequestForm sourceName={sourceName} />
        </div>
      </UContainer>
    </section>
  );
}
