import React from "react";
import { Link } from "react-router-dom";
import "./TagsList.scss";

export default function TagsList({ items, className }) {
  let classNameInner = ["tags-list"];
  if (className) classNameInner.push(className);
  classNameInner = classNameInner.join(" ");

  return (
    <div className={classNameInner}>
      <ul className="tags-list__list">
        {(items || []).map(item => (
          <li key={item.name + item.link} className="tags-list__item">
            {item.link ? (
              <Link to={item.link} className="tags-list__link">
                {item.name}
              </Link>
            ) : (
              <div className="tags-list__link">{item.name}</div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
