import React from "react";
import "./u-preloader.scss";

export default function UPreloader({ className, transparent, inline, ...restProps }) {
  className = className || "";

  if (transparent) {
    className += " _transparent";
  }

  if (inline) {
    className += " _inline";
  }

  return (
    <div className={`u-preloader ${className || ""}`} {...restProps}>
      <div className="u-preloader__loading"></div>
    </div>
  );
}
