import React from "react";
import "./UPageContainer.scss";
import "../UContainer/UContainer.scss";
import crossPlatformImage from "../../helpers/crossPlatformImage";
import lines from "../../assets/images/uCaseStudies/lines.svg";
import contactLines from "../../assets/images/uCaseStudies/contact-lines.svg";

export default function UPageContainer({ children, className, isContactPage = false, ...restProps }) {
  return (
    <>
      <div
        className="u_page_container-lines"
        data-scroll-lines
        style={{
          backgroundImage: `url(${crossPlatformImage(isContactPage ? contactLines : lines)})`,
        }}
      />
      <section className={`u_page_container ${className || ""}`} {...restProps}>
        {children}
      </section>
    </>
  );
}
